<template>
	<div class="mainTem">
		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">三方应用配置管理</span>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<el-button type="success" @click="openDioalog()" size="small" icon="el-icon-plus">{{$t('c0246c55b9cac963')}}
					</el-button>

					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>

		<div class="filterCon" style="">
			<ul class="filterConList">
				<!-- <li>
					<span>{{$t('i18nn_b00576fed9b3a1f2')}}</span>
					<el-input type="text" v-model="filterData.userName" size="small" clearable @keyup.enter.native="initData()"
						maxlength="50" :placeholder="$t('FormMsg.Please_Enter')" style="width: 180px;" />
				</li> -->
				<!-- <li>
					<span>{{$t('c944a6686d996ab3')}}</span>
					<whNoAllSelect ref="whNoAllSelect" @changeData="changWhNo" :width="200"></whNoAllSelect>
				</li> -->
				<li>
					<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
					<el-input type="text" v-model="filterData.keyword" size="small" clearable
						@keyup.enter.native="initData()" maxlength="50" :placeholder="$t('custom_enterfuzzysearch')"
						style="width: 180px;" />
				</li>
				<li>
					<el-button icon="el-icon-search" size="small" type="primary" @click="initData()">
						{{ $t('1e7246dd6ccc5539') }}</el-button>
				</li>
			</ul>
		</div>


		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">

			<el-table id="out-table2" ref="multipleTable" :data="tableData" stripe :border="true"
				:height="$store.state.tableMaxHeight2" style="width: 100%" size="small">
				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
					:label="$t('Storage.tableColumn.no')"></el-table-column>
				
				<!-- {"tpCode":"","apCode":"","apName":"","appId":"","appKey":"","appSecret":"","authUrl":""} -->
				
				<el-table-column prop="tpCode" :label="'tpCode'"></el-table-column>
				<el-table-column prop="apCode" :label="'apCode'"></el-table-column>
				<el-table-column prop="apName" :label="'apName'"></el-table-column>
				<el-table-column prop="appId" :label="'appId'"></el-table-column>
				<el-table-column prop="appKey" :label="'appKey'"></el-table-column>
				<el-table-column prop="appSecret" :label="'appSecret'"></el-table-column>
				<el-table-column prop="authUrl" :label="'authUrl'"></el-table-column>

				<el-table-column prop="remark" :label="$t('15b3627faddccb1d')">
					<template slot-scope="scope">
						<el-popover placement="top" trigger="hover">
							<div class="pre-text">{{ scope.row.remark }}</div>
							<span class="over_ellipsis" slot="reference">{{scope.row.remark}}</span>
						</el-popover>
					</template>
				</el-table-column>
				<el-table-column prop="" :label="$t('i18nn_43a3586339251494')" min-width="200">
					<template slot-scope="scope">
						<div>
							<ul>
								<li>
									<span>{{$t('i18nn_e1911e9360047dcb')}}</span>:{{scope.row.updateTime}}
								</li>
								<li>
									<span>{{$t('i18nn_e8564657bbe9ca53')}}</span>:{{scope.row.createTime}}
								</li>
							</ul>
						</div>
					</template>
				</el-table-column>
				<el-table-column :label="$t('Storage.tableColumn.operation')" width="200px" fixed="right">
					<template slot-scope="scope">
						<el-button @click="delAction($event, scope.row)" type="danger" size="mini" icon="el-icon-minus">
							{{ $t('FormMsg.Delete') }}</el-button>
					</template>
				</el-table-column>
			</el-table>

			<div class="tableCon_pagination">
				<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
			</div>
		</div>

		<!-- 新增 -->
		<ThirdAuthListAdd :openTime="addBindOpenTime" @success="addCallBack"></ThirdAuthListAdd>

	</div>
</template>
<script>
	// import AccountUserList from '@/components/WarehouseCenter2/AccountBind/AccountUserList.vue';
	// import whNoAllSelect from '@/components/WarehouseCenter2/components/whNoAllSelect.vue';
	// import dialogWarehouse from '@/components/WarehouseCenter2/SysConfig/dialogWarehouse.vue';

	import ThirdAuthListAdd from '@/components/WarehouseCenter2/ThirdAuth/ThirdAuthListAdd.vue';

	// import excelUtilsNew from '@/utils/excelUtilsNew.js';
	// import barcode from '@/components/Common/barcode.vue';
	// import whNoSelect from '@/components/WarehouseCenter2/components/whNoSelect.vue';
	export default {

		components: {
			// AccountUserList,
			// whNoAllSelect,
			// dialogWarehouse,
			ThirdAuthListAdd
			// whNoSelect
		},
		data() {
			return {
				addBindOpenTime: '',

				loading: false,

				loading_load: false,
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				selectOption: {
					wh_no: []
				},
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序
					// userName: '',
					// whNo: '',
					keyword:''
				}
			};
		},
		activated() {
			this.initData();
		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {
			// this.initData();
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				this.getPageData();
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.filterData.whNo = data.code;
				this.initData();
			},

			//打开新增编辑，弹窗
			openDioalog() {
				this.addBindOpenTime = new Date().getTime();
			},
			addCallBack() {
				this.getPageData();
			},

			//删除
			delAction(event, row) {
				this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
						type: 'warning'
					})
					.then(() => {
						this.delDataAction(event, row);
					})
					.catch(() => {

					});
			},
			//删除
			delDataAction(event, row) {
				event.stopPropagation();
				console.log('delDataAction', row);

				this.postData(this.$urlConfig.WhTripartiteAuthConfigDel + '/' + row.id, {}, 'delete');
			},

			//提交信息
			postData(url, formData, type) {
				// let _this = this;
				this.loading = true;
				this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				// formData.state = formData.state ? '0' : '1';
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('tips.submitSuccess'));
					console.log(data);

					this.loading = false;
					this.loading_load = false;
					if (200 == data.code) {
						this.dialogFormVisible = false;
						this.getPageData();
						if ('delete' == type) {
							this.$message({
								type: 'success',
								message: this.$t('tips.successfullyDelete')
							});
						} else {
							this.$message.success(this.$t('tips.submitSuccess'));
						}
					} else {
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('204ffab8a6e01870'),
					});
				});
			},

			//查询数据
			// serPageData() {
			// 	this.pagination.current_page = 1;
			// 	this.getPageData();
			// },

			//请求分页数据
			getPageData() {
				// let _this = this;
				this.loading_load = true;

				this.$http
					.put(this.$urlConfig.WhTripartiteAuthConfigPageList, {
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size, //当前页显示数目

						keyword: this.filterData.keyword ? this.filterData.keyword : null,
						// whNo: this.filterData.whNo ? this.filterData.whNo : null,
					})
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);

						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},


		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	@import url(@/assets/css/client_module.less);
</style>