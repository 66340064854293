<template>
	<div>
		<!--  -->
		<!-- <el-dialog :title="$t('c09a4ec2fe473b0a')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="1000px"
		v-loading="loadingUpload"> -->
		<el-drawer :wrapperClosable="false" :title="$t('c0246c55b9cac963')" append-to-body :visible.sync="dialogFile"
			:direction="'rtl'" size="800px">
			<div>
				<el-form ref="form" :rules="formRules" :model="form" label-width="150px" v-loading="loading"
					:element-loading-text="$t('i18nn_1b4525c800280581')">

					<!-- {"tpCode":"","apCode":"","apName":"","appId":"","appKey":"","appSecret":"","authUrl":""} -->

					<el-form-item :label="'tpCode'" prop="">
						<!-- <el-input type="text" clearable v-model="form.tpCode"></el-input> -->
						
						
						<el-select filterable clearable v-model="form.tpCode" :placeholder="$t('2ad108ab2c560530')"
							style="width: 200px;">
							<el-option v-for="item in selectOption.lgs_auth_tp_code" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')"
								:value="item.code">
								<div class="sel_option_s1" style="">
									<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
									<span class="sel_option_code" style="">{{ item.code }}</span>
								</div>
							</el-option>
						</el-select>
						
					</el-form-item>
					<el-form-item :label="'apCode'" prop="">
						<el-input type="text" v-model="form.apCode"></el-input>
					</el-form-item>
					<el-form-item :label="'apName'" prop="">
						<el-input type="text" v-model="form.apName"></el-input>
					</el-form-item>
					<el-form-item :label="'appId'" prop="">
						<el-input type="text" v-model="form.appId"></el-input>
					</el-form-item>
					<el-form-item :label="'appKey'" prop="">
						<el-input type="text" v-model="form.appKey"></el-input>
					</el-form-item>
					<el-form-item :label="'appSecret'" prop="">
						<el-input type="text" v-model="form.appSecret"></el-input>
					</el-form-item>
					<el-form-item :label="'authUrl'" prop="">
						<el-input type="text" v-model="form.authUrl"></el-input>
					</el-form-item>

					<el-form-item :label="$t('Storage.tableColumn.remark')" prop="">
						<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.remark" :maxlength="1000"
							show-word-limit>
						</el-input>
					</el-form-item>
				</el-form>
			</div>


			<div class="drawer-footer">
				<el-button type="primary" plain @click="dialogFile = false">{{ $t('FormMsg.Close') }}</el-button>
				<el-button type="primary" icon="el-icon-tickets" @click="submitForm('form')">{{ $t('FormMsg.Save') }}</el-button>
			</div>
		</el-drawer>

		<!--选择账号-->
		<!-- <el-dialog :title="$t('i18nn_b17342e22700eb32')" append-to-body :close-on-click-modal="false"
		:visible.sync="dialogSelVisible" width="800px" top="0px"> -->
		<!-- <el-drawer :wrapperClosable="false" :title="$t('i18nn_b17342e22700eb32')" append-to-body
			:visible.sync="dialogSelVisible" :direction="'rtl'" size="800px">
			<div>
				<AccountUserList :openTime="AccountUserListOpenTime" :multiple="true" @selectRow="selAccount"
					@sure="SureUserSel"></AccountUserList>
			</div>
		</el-drawer> -->
		<!-- </el-dialog> -->

		<!-- 选择 -->
		<!-- <dialogWarehouse :openTime="whOpenTime" @sure="sureSel"></dialogWarehouse> -->
	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';
	// import whNoSelect from '@/components/WarehouseCenter2/components/whNoSelect.vue';

	// import AccountUserList from '@/components/WarehouseCenter2/AccountBind/AccountUserList.vue';
	// import whNoAllSelect from '@/components/WarehouseCenter2/components/whNoAllSelect.vue';
	// import dialogWarehouse from '@/components/WarehouseCenter2/SysConfig/dialogWarehouse.vue';

	// import HyElWhUpLoad from '@/components/Common/HyElWhUpLoad.vue';
	
	import {
		clearObjectVal
	} from '@/utils/common.js';
	
	export default {
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		//   meta: [{ // set meta
		//     name: '互易天下-买家中心-收货账单',
		//     content: '互易天下-厂家共享平台-买家中心-收货账单'
		//   }],
		//   // link: [{                 // set link
		//   //   rel: 'asstes',
		//   //   href: 'https://assets-cdn.github.com/'
		//   // }]
		// },
		props: {
			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			row: {}
			// type: {//1提交附件并，提交打托完成，其他仅提交附件
			// 	// default: function() {
			// 	// 	return '';
			// 	// },
			// 	// type: String
			// }
		},
		components: {
			// AccountUserList,
			// dialogWarehouse
		},
		data() {
			return {
				dialogFile: false,
				loading: false,

				// dialogFormVisible: false,
				// dialogFormStatus: 0, //0-新增，1-修改

				// addType:'0',
				AccountUserListOpenTime: '',

				whOpenTime: '',

				dialogSelVisible: false,

				// loading: false,
				// {"tpCode":"","apCode":"","apName":"","appId":"","appKey":"","appSecret":"","authUrl":""}
				form: {
					// id: null, //"数据ID",
					tpCode: '', //
					apCode: '',
					apName: '',
					appId: '', //
					appKey: '',
					appSecret: '',
					authUrl: '',
					remark: ""
				},

				formRules: {
					// bckId: [{
					// 	required: true,
					// 	message: this.$t('FormMsg.Please_select'),
					// 	trigger: 'change'
					// }],
					// whNo: [{
					// 	required: true,
					// 	message: this.$t('FormMsg.Please_select'),
					// 	trigger: 'change'
					// }]
				},

				selectOption: {
					lgs_auth_tp_code: [],
				}
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.dialogFile = true;
				this.initData();
			}
		},
		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			// this.initData();
			// this.getDicData();
			//数据字典
			getDicData(['lgs_auth_tp_code'],
				(data)=>{
					this.selectOption.lgs_auth_tp_code = data['lgs_auth_tp_code'];
			});
		},
		methods: {
			initData() {
				// let form = Object.assign({}, formParm);
				// console.log('form', form);
				// // 重置
				this.resetForm('form');

			},

			changWhNo(data) {
				console.log('changWhNo', data);
				this.form.whNo = data.code;
				// this.filterData.userId = data.userId;
				// this.initData();
			},

			//打开账号选择弹窗
			// openSelDialog() {
			// 	this.AccountUserListOpenTime = new Date().getTime();
			// 	this.dialogSelVisible = true;
			// },
			//选择仓库
			// changeDataWhNo(data){
			// 	console.log('changeDataWhNo',data);
			// 	this.form.whNo = data.code;
			// },
			//选择账号
			// selAccount(row) {
			// 	console.log('selAccount', row);
			// 	// this.form.bckId = row.userId;
			// 	// this.form.userName = row.userName;

			// 	let hasUserIndex = this.form.userArr.findIndex(itemUser => row.userId == itemUser.userId);
			// 	if (hasUserIndex <= -1) {
			// 		this.form.userArr.push(row)
			// 	}
			// 	// this.form.userArr = row;

			// 	this.dialogSelVisible = false;
			// },
			// SureUserSel(rows) {
			// 	// let userArr = [];
			// 	rows.map(item => {
			// 		let hasUserIndex = this.form.userArr.findIndex(itemUser => item.userId == itemUser.userId);
			// 		if (hasUserIndex <= -1) {
			// 			this.form.userArr.push(item)
			// 		}
			// 	});
			// 	// this.form.userArr = rows;
			// 	this.dialogSelVisible = false;
			// },
			// delUserRow(event, row, index) {
			// 	this.form.userArr.splice(index, 1);
			// },

			// addRow() {
			// 	this.whOpenTime = new Date().getTime();
			// },
			// clearRow() {
			// 	this.form.whNoArr = [];
			// },
			// //删除
			// delRow(event, row, index) {
			// 	this.form.whNoArr.splice(index, 1);
			// },

			// sureSel(val) {
			// 	// this.form.whNoArr = val;

			// 	// this.form.whNos = val.map(v => v.whNo);
			// 	val.forEach(item => {
			// 		this.form.whNoArr.push(item);
			// 	});
			// },

			//提交信息
			submitForm(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						let formData = Object.assign({}, this.form);
						//浅拷贝、对象属性的合并
						// if (0 === this.dialogFormStatus) {
						// formData.id = null;
						// formData.userName = null;

						this.postData(this.$urlConfig.WhTripartiteAuthConfigAdd, formData,()=>{
							this.$message.success(this.$t('tips.submitSuccess'));
							this.$emit('success');
							this.dialogFile = false;
						});

						// let whArr = formData.whNoArr;
						// let userArr = formData.userArr;

						// if (whArr.length <= 0 || userArr.length <= 0) {
						// 	this.$message.warning(this.$t('i18nn_17e7c7b16731a7e5'));
						// 	return;
						// }

						// if (whArr.length * userArr.length > 100) {
						// 	this.$message.warning('绑定数据超过100,请减少绑定数据');
						// 	return;
						// }

						// for (let j = 0, lnj = userArr.length; j < lnj; j++) {
						// 	// 账号
						// 	let userObj = userArr[j];
						// 	formData.userName = userObj.userName;
						// 	formData.bckId = userObj.userId;

						// 	for (let i = 0, ln = whArr.length; i < ln; i++) {
						// 		//仓库
						// 		let whNo = whArr[i].whNo;
						// 		formData.whNo = whNo;
						// 		this.loading = true;
						// 		let res = await this.$http.put(this.$urlConfig.WhAccountBindAdd, formData);
						// 		if (res.data && 200 == res.data.code) {
						// 			// this.$message.success('添加成功:' +"("+formData.userName+")"+ whNo);
						// 			this.$notify({
						// 				title: this.$t('i18nn_0e8a3154ae62784f'),
						// 				message: "(" + formData.userName + ")" + whNo,
						// 				type: 'success'
						// 			});
						// 		} else {
						// 			// this.$message.warning('添加失败:' +"("+formData.userName+")"+ whNo);
						// 			this.$notify({
						// 				title: this.$t('i18nn_91d7402a8bcd9fad'),
						// 				message: "(" + formData.userName + ")" + whNo,
						// 				type: 'warning',
						// 				duration: 0
						// 			});
						// 		}
						// 	}
						// 	//结束遍历
						// 	if (j == (userArr.length - 1)) {
						// 		this.loading = false;
						// 		this.dialogFile = false;
						// 		// this.getPageData();
						// 		this.$emit('success')
						// 	}
						// }

						// } else {
						// 	// formData.userId = this.UserInfo.id;
						// 	// this.postData(this.$urlConfig.WhMyGoodsSkuUpdate+'/'+this.cusUserId, formData);
						// }
					} else {
						console.log('error submit!!');
						this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('204ffab8a6e01870'),
						});
						return false;
					}
				});
			},
			//重置输入框
			resetForm(formName) {
				console.log(formName);
				// console.log(this.$refs[formName]);

				if (this.$refs[formName]) {
					this.$refs[formName].resetFields();
				} else {
					console.log('this.$refs[formName]', this.$refs[formName]);
				}
				this.form = clearObjectVal(this.form);
				// this.form.bckId = '';
				// this.form.userName = '';
				// this.form.userArr = [];
				// this.form.remark = '';
				// this.form.whNoArr = [];
				// this.form.whNo = "";
				// this.CateValue = [];
				// if (this.$refs.hyUpLoadImg1) {
				// 	this.$refs.hyUpLoadImg1.initUrl('');
				// }
			},

			//提交信息
			// postData(url, formData, type) {
			// 	// let _this = this;
			// 	this.loading = true;
			// 	this.loading_load = true;
			// 	let HttpType = {};
			// 	if ('delete' == type) {
			// 		HttpType = this.$http.delete(url, formData);
			// 	} else {
			// 		HttpType = this.$http.put(url, formData);
			// 	}
			// 	// formData.state = formData.state ? '0' : '1';
			// 	HttpType.then(({
			// 		data
			// 	}) => {
			// 		console.log(this.$t('tips.submitSuccess'));
			// 		console.log(data);

			// 		this.loading = false;
			// 		this.loading_load = false;
			// 		if (200 == data.code) {
			// 			this.dialogFormVisible = false;
			// 			this.getPageData();
			// 			// this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
			// 			//   type: 'success',
			// 			//   //confirmButtonText: this.$t('204ffab8a6e01870'),
			// 			// });
			// 			// this.$message.success(this.$t('tips.submitSuccess'));
			// 			if ('delete' == type) {
			// 				this.$message({
			// 					type: 'success',
			// 					message: this.$t('tips.successfullyDelete')
			// 				});
			// 			} else {
			// 				// this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
			// 				// 	type: 'success'
			// 				// });
			// 				this.$message.success(this.$t('tips.submitSuccess'));
			// 			}
			// 		} else {
			// 			// if (!data.msg) {
			// 			//   data.msg = this.$t('tips.submitError');
			// 			// }
			// 			this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
			// 				type: 'warning'
			// 				//confirmButtonText: this.$t('204ffab8a6e01870'),
			// 			});
			// 		}
			// 	}).catch(error => {
			// 		console.log(error);
			// 		console.log(this.$t('tips.submitError'));
			// 		this.loading = false;
			// 		this.loading_load = false;
			// 		this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
			// 			type: 'warning'
			// 			//confirmButtonText: this.$t('204ffab8a6e01870'),
			// 		});
			// 	});
			// },

			//提交数据
			postData(url, formData, callback) {
				// let _this = this;
				this.loading = true;

				this.$http
					.put(url, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
						
						this.loading = false;
						if (200 == data.code) {
							callback();
						} else {
							if (!data.msg) {
								data.msg = this.$t('dbe331ab679cd67f');
							}
							this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('i18nn_a7d2e953195a5588'));
						this.loading = false;
						this.$alert(this.$t('33bae59bb784a109'), this.$t('cc62f4bf31d661e3'), {
							type: 'warning'
						});
					});
			},
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_container_size', 'wh_packing_type'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				this.selectOption.wh_container_size = data.data['wh_container_size'];
			// 				this.selectOption.wh_packing_type = data.data['wh_packing_type'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('hytxs0000032');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('hytxs0000033'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>